<template>
  <div class="collection-exchange-container">
    <p class="collection-exchange-title">兑换详情</p>
    <!-- 抽奖次数 -->
    <div class="lottery-size-bg col-center">
      <p class="lottery-size-title">抽奖次数</p>
      <div class="row-center">
        <img
          @click="valueMinus"
          style="width:18px;height:auto;margin-top: 8px;"
          :style="this.value <= 1 || materialMaxLength == 0 ? 'opacity: 0.6;' : ''"
          src="../../assets/icon/gashapon/minus.png"
        />
        <p class="lottery-size">{{collectionExchangeInfo.num *  value}}次</p>
        <img
          @click="valuePlus"
          :style="this.value > materialMaxLength || materialMaxLength == 0 ? 'opacity: 0.6' : ';'"
          style="width:18px;height:auto;margin-top: 8px;"
          src="../../assets/icon/gashapon/plus.png"
        />
      </div>

      <van-stepper
        ref="stepper"
        style="visibility: hidden;height: 0;"
        v-model="value"
        @change="valueChange"
        @plus="valuePlus"
        @minus="valueMinus"
        min="1"
        button-size="22"
        disable-input
        :disable-plus="materialMaxLength == 0 || materialMaxLength == value"
      />
    </div>
    <!-- 转换图标 -->
    <img
      class="collection-exchange-next"
      src="../../assets/icon/gashapon/next.png"
    />
    <!-- 兑换所需藏品 -->
    <div class="collection-exchange-content">
      <div
        class="collection-exchange-content-item row-between"
        v-for="(item,index) in collectionExchangeInfo.skuList"
        :key="index"
        :style="item.isOpecity ? 'opacity: 0.6;': ''"
      >
        <div class="row-center">
          <div class="collection-exchange-item-img-bg row-center">
            <img :src="item.image" />
          </div>
          <div>
            <p class="collection-exchange-name">{{item.name}}</p>
            <p class="collection-exchange-amount">需要{{ item.amount * value }}份</p>
          </div>
        </div>
        <div
          @click="skuNoExchange(item)"
          class="row-center collection-exchange-right"
        >
          <p>更换</p>
          <img src="../../assets/icon/next.png" />
        </div>
      </div>
    </div>
    <div
      style="z-index:1999;"
      class="btns row-center"
    >
      <div
        :style="!this.isExchangeConfrim ? 'opacity: 0.6;' : ''"
        class="comfirm-submit"
        @click="confirmExchange"
      ></div>
    </div>
    <el-dialog
      title="尚未设置资产密码"
      :visible.sync="donationVisible"
      @confirm="$router.push('/resetPwd')"
      class="donation-dialog"
      center
    >
      <span
        slot="footer"
        style="margin-top:2rem;"
        class="dialog-footer row-center"
      >
        <el-button @click="donationVisible = false">取 消</el-button>
        <div
          @click="$router.push('/resetPwd')"
          class="confirm-button row-center"
        >去设置</div>
      </span>
    </el-dialog>
    <van-popup
      closeable
      v-model="show"
      position="bottom"
      style="height: 411px;background: rgba(21, 21, 21, 0.85);"
      :close-on-click-overlay="false"
    >
      <div v-if="payStep == 1">
        <p class="donation-popup-title">安全验证</p>
        <!-- 密码输入框 -->
        <p class="donation-popup-tip">验证资产密码</p>
        <van-password-input
          :value="pwdValue"
          :gutter="8"
          :focused="showKeyboard"
          @focus="showKeyboard = true"
        />
        <!-- 数字键盘 -->
        <van-number-keyboard
          v-model="pwdValue"
          :show="showKeyboard"
          @blur="show = false"
        />
      </div>
    </van-popup>
    <!-- 弹窗 -->
    <div
      v-if="lottertShade"
      class="lottery-shade-bg"
    >
    </div>
    <div
      v-if="lottertShade"
      @click="closeLotteryExchange()"
      class="lottery-content"
    >
      <div class="lottery-content-bg col-center">
        <img src="../../assets/icon/gashapon/right.png" />
        <p class="lottery-content-title">兑换成功</p>
        <p class="lottery-content-tip">抽奖次数+{{collectionExchangeInfo.num * value}}</p>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import api from '../../api/index-client'
import md5 from 'js-md5';
import { Field,PasswordInput,NumberKeyboard,Popup,Stepper } from 'vant';
import { Dialog,Button } from 'element-ui';
Vue.use(Dialog);
Vue.use(Stepper);
Vue.use(Field);
Vue.use(PasswordInput);
Vue.use(NumberKeyboard);
Vue.use(Button);
Vue.use(Popup);

export default {
  data () {
    return {
      value: 1,
      materialMaxLength: 5,
      donationVisible: false,
      show: false,
      pwdValue: '',
      payStep: 1,
      showKeyboard: true,
      lottertShade: false,
      collectionExchangeInfo: {},
      userPlanAssetsList: {},
      submitAssetsList: {},
      isConfirmExchange: true,
      isExchangeConfrim: false,
    }
  },
  watch: {
    pwdValue () {
      if (this.pwdValue.length === 6) {
        this.testOldPwd()
      }
    },
  },
  mounted () {
    this.collectionExchangeInfo = JSON.parse(localStorage.getItem('collectionExchangeItem')) ? JSON.parse(localStorage.getItem('collectionExchangeItem')) : {}
    this.value = sessionStorage.getItem('value') ? Number(sessionStorage.getItem('value')) : 1
    this.getUserPlanAssets()
  },
  methods: {
    // 获取用户资产列表
    getUserPlanAssets () {
      api
        .get('lottery/exchange/getUserPlanAssets?planId=' + this.collectionExchangeInfo.id)
        .then(result => {
          if (result.data.success) {
            this.userPlanAssetsList = result.data.data
            // 获取最大组数
            let arr = []
            this.collectionExchangeInfo.skuList.map(i => {
              arr.push(parseInt(this.userPlanAssetsList[i.skuNo].length / i.amount))
            })
            arr.sort((a,b) => a - b)
            this.materialMaxLength = arr[0] == 0 ? 1 : arr[0]
            this.isExchangeConfrim = arr[0] == 0 ? false : true
            if (sessionStorage.getItem('value')) {
              this.value = Number(sessionStorage.getItem('value'))
              this.selectSkuNo()
            } else if (this.materialMaxLength > 0) {
              this.value = 1
              this.selectSkuNo()
            }
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../../assets/icon/toast-error.png'),
            });
          }
        })
    },
    // 校验资产密码
    testOldPwd () {
      api
        .post('user/verifyAssetsPassword',{ assetsPassword: md5(this.pwdValue) })
        .then(result => {
          if (result.data.success) {
            this.exchangeConfirm()
          } else {
            this.pwdValue = ''
            this.$toast({
              message: result.data.msg,
              icon: require('../../assets/icon/toast-error.png'),
            });
          }
        })
    },
    // j控制步进器减
    lotteryValueMinus () {
      console.log(this.$refs.stepper);
    },
    exchangeConfirm () {
      var uniqueCodes = []
      for (let i in this.submitAssetsList) {
        this.submitAssetsList[i].map(j => {
          uniqueCodes.push(j)
        })
      }
      api
        .post('lottery/exchange/confirm',{ uniqueCodes: uniqueCodes,planId: this.collectionExchangeInfo.id,pwd: md5(this.pwdValue),num: this.value })
        .then(res => {
          if (res.data.success) {
            this.show = false
            this.pwdValue = ''
            this.lottertShade = true
          } else {
            this.pwdValue = ''
            this.$toast({
              message: res.data.msg,
              icon: require('../../assets/icon/toast-error.png'),
            });
          }
        })
    },
    exchangeConfirmVerify () {
      var uniqueCodes = []
      for (let i in this.submitAssetsList) {
        this.submitAssetsList[i].map(j => {
          uniqueCodes.push(j)
        })
      }
      api
        .post('lottery/exchange/confirmVerify',{ uniqueCodes: uniqueCodes,planId: this.collectionExchangeInfo.id,num: this.value })
        .then(result => {
          if (result.data.success) {
            this.show = true
          } else {
            this.pwdValue = ''
            this.$toast({
              message: result.data.msg,
              icon: require('../../assets/icon/toast-error.png'),
            });
          }
        })
    },
    valueMinus () {
      console.log(this.value);
      if (this.value > 1 && this.materialMaxLength != 0) {
        this.value -= 1
        this.plusMinusSelectSkuNo(this.value)
      }
    },
    valuePlus () {
      console.log(this.value);
      if (this.value <= this.materialMaxLength && this.materialMaxLength != 0) {
        this.value += 1
        this.plusMinusSelectSkuNo(this.value)
      }
    },
    valueChange (val) {
      // this.activeIndex = `${val}`
    },
    plusMinusSelectSkuNo (account) {
      console.log();
      this.collectionExchangeInfo.skuList.map(i => {
        this.submitAssetsList[i.skuNo] = []
        this.userPlanAssetsList[i.skuNo].map((m,n) => {
          if (n < i.amount * account) {
            this.submitAssetsList[i.skuNo].push(m.uniqueCode)
          }
        })
      })
      console.log(this.submitAssetsList);
      this.$forceUpdate()
    },
    // 初始选择藏品skuNo
    selectSkuNo () {
      this.collectionExchangeInfo.skuList.map(i => {
        if (this.userPlanAssetsList[i.skuNo].length >= i.amount * this.value) {
          i.isOpecity = false
          if (!localStorage.getItem('submitAssetsList')) {
            this.submitAssetsList[i.skuNo] = []
            this.userPlanAssetsList[i.skuNo].map((m,n) => {
              if (n < i.amount * this.value) {
                this.submitAssetsList[i.skuNo].push(m.uniqueCode)
              }
            })
          } else {
            this.submitAssetsList = JSON.parse(localStorage.getItem('submitAssetsList'))
          }
        } else {
          i.isOpecity = true
        }
      })
      this.$forceUpdate()
      this.$store.commit('HIDE_APPLOADING')
      console.log(this.collectionExchangeInfo);
    },
    closeLotteryExchange () {
      this.lottertShade = false
      this.getUserPlanAssets()
    },
    // 确认兑换
    confirmExchange () {
      if (this.isExchangeConfrim) {
        // this.collectionExchangeInfo.skuList.map(i => {
        //   if (this.userPlanAssetsList[i.skuNo].length < this.value) {
        //     this.isConfirmExchange = false
        //   }
        // })
        // if (!this.isConfirmExchange) {
        //   this.$toast({
        //     message: "藏品不足！",
        //     icon: require('../../assets/icon/toast-error.png'),
        //   });
        // } else {
        api
          .get('userinfo')
          .then(result => {
            if (result.data.success) {
              if (result.data.data.hasAssetsPassword) {
                this.exchangeConfirmVerify()
              } else {
                this.donationVisible = true
              }
            } else {
              this.$toast({
                message: result.data.msg,
                icon: require('../../assets/icon/toast-error.png'),
              });
            }
          })
        // }
      }


    },
    // 选择藏品
    skuNoExchange (item) {
      if (this.userPlanAssetsList[item.skuNo].length < item.amount) {
        this.$toast({
          message: '藏品不足',
          icon: require('../../assets/icon/toast-error.png'),
        });
      } else {
        sessionStorage.setItem('value',this.value)
        localStorage.setItem('submitAssetsList',JSON.stringify(this.submitAssetsList))
        this.$router.push('/collectionSkuNoExchange?skuNo=' + item.skuNo + '&lotteryCode=' + this.$route.query.lotteryCode)
      }
    }
  },
}
</script>
<style lang="less" scoped>
.collection-exchange-container {
  width: 100vw;
  min-height: 100vh;
  height: auto;
  position: absolute;
  top: 0;
  background: #fffaf4;
  .collection-exchange-title {
    width: 100%;
    text-align: center;
    font-size: 1.125rem;
    font-weight: 900;
    color: #1c1c1c;
    line-height: 1.5625rem;
    padding-top: 1.5rem;
  }
  .lottery-size-bg {
    width: 92vw;
    height: 22.4vw;
    margin: 30px 3vw 0 3vw;
    background: #f3ddd4;
    border-radius: 10px;
    .lottery-size-title {
      font-size: 12px;
      font-weight: 500;
      color: #080808;
    }
    .lottery-size {
      font-size: 27px;
      margin: 8px 35px 0 35px;
      font-weight: bold;
      color: #080808;
    }
  }
  .collection-exchange-next {
    width: 9vw;
    height: 9vw;
    margin: 15px 45.5vw 16px 45.5vw;
  }
  .collection-exchange-content {
    width: 92vw;
    height: auto;
    margin: 4vw;
    padding: 10px 0;
    background: #f3ddd4;
    border-radius: 10px;
    margin-bottom: 71px;
    .collection-exchange-content-item {
      width: 86vw;
      height: 21.34vw;
      margin-left: 3vw;
      background: #de8466;
      margin-bottom: 10px;
      border-radius: 10px;
      .collection-exchange-item-img-bg {
        width: 17vw;
        height: 17vw;
        background-image: url('../../assets/icon/gashapon/collection-exchange-item-img-bg.png');
        background-size: 100% auto;
        background-repeat: no-repeat;
        margin-right: 9px;
        margin-left: 3vw;
        img {
          width: 14vw;
          height: 14vw;
        }
      }
      .collection-exchange-name {
        font-size: 14px;
        font-weight: 500;
        color: #ffffff;
      }
      .collection-exchange-amount {
        font-size: 12px;
        font-weight: 500;
        margin-top: 10px;
        color: #ffffff;
      }
    }
    .collection-exchange-right {
      p {
        font-size: 12px;
        font-weight: 500;
        color: #ffffff;
      }
      img {
        width: 5px;
        height: auto;
        margin: 0 14px;
      }
    }
    .collection-exchange-content-item:last-child {
      margin-bottom: 0px;
    }
  }
  .btns {
    height: 4rem;
    background: #fffaf4;
    padding-top: 0.4375rem;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    .comfirm-submit {
      width: 60vw;
      height: 16vw;
      background-image: url('../../assets/icon/gashapon/exchange-button.png');
      background-size: 100% auto;
      background-repeat: no-repeat;
    }
    .comfirm-submit:active {
      opacity: 0.6;
    }
  }
}
.van-popup {
  .donation-popup-title {
    margin: 1.625rem 0 1.25rem 0;
    text-align: center;
    font-size: 1.125rem;
    font-family: lantingheiweight;
    font-weight: 700;
    color: #f0f0f0;
  }
  .donation-popup-donationPay {
    text-align: center;
    font-size: 2.1875rem;
    font-family: SFUIDisplay-Bold, SFUIDisplay;
    font-weight: bold;
    color: #ffffff;
    line-height: 2.625rem;
    margin-bottom: 2.4375rem;
  }
  .donation-popup-tip {
    margin: 0 0 0.875rem 1rem;
    font-size: 0.875rem;
    font-family: lantingheiweight;
    font-weight: 600;
    color: #666666;
    line-height: 19px;
  }
  .van-password-input__security li {
    background: #565656;
    border-radius: 0.25rem;
  }
  .van-number-keyboard {
    background: rgba(21, 21, 21, 0.85);
  }
  /deep/.van-number-keyboard .van-key {
    background-color: #646465;
    box-shadow: 0px 1px 0px 0px #000000;
    font-family: Helvetica;
    color: #ffffff;
  }
}
.lottery-shade-bg {
  z-index: 2001;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #000000;
  opacity: 0.5;
}
.lottery-content {
  z-index: 2002;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  .lottery-content-bg {
    width: 80vw;
    height: 80vw;
    margin: 30vw 10vw 0 10vw;
    background: #f9dcd4;
    border-radius: 10px;
    img {
      width: 30%;
      height: 30%;
    }
    .lottery-content-title {
      font-size: 22px;
      font-weight: bold;
      color: #3d3d3d;
      margin: 60px 0 20px 0;
    }
    .lottery-content-tip {
      font-size: 16px;
      font-weight: 800;
      color: #3d3d3d;
    }
  }
}
.van-stepper {
  color: #f0f0f0;
  /deep/ .van-stepper__minus,
  /deep/ .van-stepper__plus {
    background: transparent !important;
    color: #f0f0f0;
  }
  /deep/ input {
    background: #545454 !important;
    color: #f0f0f0;
  }
}
</style>